
const courseNavigationDataConfig = [{
  name: "IIT-JEE",
  url: "/course?Exam=IIT%20JEE_CT",
  content: [
    { name: "Class 11", url: "/course?Exam=IIT%20JEE_CT&Class=11", next: true },
    { name: "Class 12", url: "/course?Exam=IIT%20JEE_CT&Class=12", next: true },
    { name: "Class 12+", url: "/course?Exam=IIT%20JEE_CT&Class=13", next: true }
  ]
}, {
  name: "NEET",
  url: "/course?Exam=NEET_CT",
  content: [
    { name: "Class 11", url: "/course?Exam=NEET_CT&Class=11", next: true },
    { name: "Class 12", url: "/course?Exam=NEET_CT&Class=12", next: true },
    { name: "Class 12+", url: "/course?Exam=NEET_CT&Class=13", next: true }
  ]
}, {
  name: "UP Board",
  url: "/course?Exam=UP%20Board",
  content: [
    { name: "Class 9", url: "/course?Exam=UP%20Board&Class=9", next: true },
    { name: "Class 10", url: "/course?Exam=UP%20Board&Class=10", next: true },
    { name: "Class 11", url: "/course?Exam=UP%20Board&Class=11", next: true },
    { name: "Class 12", url: "/course?Exam=UP%20Board&Class=12", next: true }
  ]
}, {
  name: "Bihar Board",
  url: "/course?Exam=Bihar%20Board",
  content: [
    { name: "Class 9", url: "/course?Exam=Bihar%20Board&Class=9", next: true },
    { name: "Class 10", url: "/course?Exam=Bihar%20Board&Class=10", next: true },
    { name: "Class 11", url: "/course?Exam=Bihar%20Board&Class=11", next: true },
    { name: "Class 12", url: "/course?Exam=Bihar%20Board&Class=12", next: true }
  ]
}, {
  name: "CBSE Board",
  url: "/course?Exam=CBSE%20Boards",
  content: [
    { name: "Class 9", url: "/course?Exam=CBSE%20Boards&Class=9", next: true },
    { name: "Class 10", url: "/course?Exam=CBSE%20Boards&Class=10", next: true },
    { name: "Class 11", url: "/course?Exam=CBSE%20Boards&Class=11", next: true },
    { name: "Class 12", url: "/course?Exam=CBSE%20Boards&Class=12", next: true }
  ]
}, {
  name: "Other Boards",
  content: [{
    name: "MP Board",
    url: "/course?Exam=MP%20Board",
    content: [
      { name: "Class 9", url: "/course?Exam=MP%20Board&Class=9", next: true },
      { name: "Class 10", url: "/course?Exam=MP%20Board&Class=10", next: true },
      { name: "Class 11", url: "/course?Exam=MP%20Board&Class=11", next: true },
      { name: "Class 12", url: "/course?Exam=MP%20Board&Class=12", next: true }
    ]
  }, {
    name: "Jharkhand Board",
    url: "/course?Exam=Jharkhand%20Board",
    content: [
      { name: "Class 9", url: "/course?Exam=Jharkhand%20Board&Class=9", next: true },
      { name: "Class 10", url: "/course?Exam=Jharkhand%20Board&Class=10", next: true },
      { name: "Class 11", url: "/course?Exam=Jharkhand%20Board&Class=11", next: true },
      { name: "Class 12", url: "/course?Exam=Jharkhand%20Board&Class=12", next: true }
    ]
  }, {
    name: "Haryana Board",
    url: "/course?Exam=Haryana%20Board",
    content: [
      { name: "Class 9", url: "/course?Exam=Haryana%20Board&Class=9", next: true },
      { name: "Class 10", url: "/course?Exam=Haryana%20Board&Class=10", next: true },
      { name: "Class 11", url: "/course?Exam=Haryana%20Board&Class=11", next: true },
      { name: "Class 12", url: "/course?Exam=Haryana%20Board&Class=12", next: true }
    ]
  }, {
    name: "Himachal Board",
    url: "/course?Exam=Himachal%20Board",
    content: [
      { name: "Class 9", url: "/course?Exam=Himachal%20Board&Class=9", next: true },
      { name: "Class 10", url: "/course?Exam=Himachal%20Board&Class=10", next: true },
      { name: "Class 11", url: "/course?Exam=Himachal%20Board&Class=11", next: true },
      { name: "Class 12", url: "/course?Exam=Himachal%20Board&Class=12", next: true }
    ]
  }, {
    name: "Chhattisgarh Board",
    url: "/course?Exam=Chhattisgarh%20Board",
    content: [
      { name: "Class 9", url: "/course?Exam=Chhattisgarh%20Board&Class=9", next: true },
      { name: "Class 10", url: "/course?Exam=Chhattisgarh%20Board&Class=10", next: true },
      { name: "Class 11", url: "/course?Exam=Chhattisgarh%20Board&Class=11", next: true },
      { name: "Class 12", url: "/course?Exam=Chhattisgarh%20Board&Class=12", next: true }
    ]
  }, {
    name: "Uttarakhand Board",
    url: "/course?Exam=Uttarakhand%20Board",
    content: [
      { name: "Class 9", url: "/course?Exam=Uttarakhand%20Board&Class=9", next: true },
      { name: "Class 10", url: "/course?Exam=Uttarakhand%20Board&Class=10", next: true },
      { name: "Class 11", url: "/course?Exam=Uttarakhand%20Board&Class=11", next: true },
      { name: "Class 12", url: "/course?Exam=Uttarakhand%20Board&Class=12", next: true }
    ]
  }, {
    name: "Rajasthan Board",
    url: "/course?Exam=Rajasthan%20Board",
    content: [
      { name: "Class 9", url: "/course?Exam=Rajasthan%20Board&Class=9", next: true },
      { name: "Class 10", url: "/course?Exam=Rajasthan%20Board&Class=10", next: true },
      { name: "Class 11", url: "/course?Exam=Rajasthan%20Board&Class=11", next: true },
      { name: "Class 12", url: "/course?Exam=Rajasthan%20Board&Class=12", next: true }
    ]
  }],
}];

const courseNavigation = [];
courseNavigationDataConfig.forEach(x => {
  if (!x.content?.length) return;
  x.content.forEach((y) => {
    if (!y.content?.length) return;
    courseNavigation.push(...y.content.map((z) => ({ name: z.name, url: z.url })).filter(x => x.url));
  });
  courseNavigation.push(...x.content.map((y) => ({ name: y.name, url: y.url })).filter(x => x.url));
});
courseNavigation.push(...courseNavigationDataConfig.map(x => ({ name: x.name, url: x.url })).filter(x => x.url));

module.exports = {
  courseNavigationDataConfig,
  courseNavigation,
}