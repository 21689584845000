/* eslint-disable max-len */
// import { BOOKS } from '../constants';

/* const teluguSIDS = [-74];
const tamilSIDS = [-104, -77];
const kannadaSIDS = [-90, -76];
const gujratiSIDS = [-214, -209];
const bengaliSIDS = [-227, -226, -153, -100, -67];
const hindiSIDS = [-220, -217, -216, -205, -195, -191, -188, -187, -184, -178, -163, -162, -161, -160, -159, -158, -157, -154, -152, -149, -147, -145, -135, -124, -109, -108, -101, -93, -92, -87, -85, -81, -80, -75, -73, -71, -69, -63, -62, -61, -50, -48, -41, -35, -34, -32, -25, -23, -22, -17, -16, -12, -11, -10, -8, -5, -4, 22, 69, 73, 77, 78, 88, 89, 90, 91];
const marathiSIDS = [-208];
const punjabiSIDS = [-133]; */

const hi = [-446, -437, -434, -433, -432, -426, -425, -422, -421, -419, -417, -414, -408, -396, -391, -388, -386, -380, -377, -374, -371, -369, -368, -367, -365, -360, -359, -357, -354, -353, -352, -351, -350, -347, -345, -333, -327, -326, -323, -302, -289, -281, -276, -275, -267, -263, -258, -221, -220, -217, -216, -205, -195, -191, -188, -187, -184, -178, -163, -162, -161, -160, -158, -157, -154, -152, -149, -147, -145, -135, -124, -109, -108, -101, -93, -92, -87, -85, -81, -80, -75, -73, -71, -69, -63, -62, -61, -58, -50, -48, -41, -35, -34, -32, -25, -23, -22, -17, -16, -12, -11, -10, -8, -5, -4, 22, 69, 73, 77, 78, 88, 89, 90, 91]; // hindi
const bn = [-397, -334, -279, -227, -226, -153, -100, -67]; // bengali
const ta = [-404, -337, -104, -77]; // tamil
const te = [-405, -338, -322, -74]; // Telugu
const gu = [-450, -449, -427, -398, -390, -385, -339, -330, -312, -311, -296, -214, -209]; // gujrati
const kn = [-399, -336, -291, -90, -76]; // kannada
const ml = [-400, -342, -262, -246, -245]; // malayalam
const mr = [-401, -343, -293, -251, -208]; // Marathi
const od = [-344, -210, -183]; // Odia
const pu = [-370, -335, -133]; // Punjabi
const as = [-413, -341, -156]; // Assamese
const en = [-452, -451, -448, -447, -445, -444, -443, -442, -441, -440, -439, -438, -436, -435, -431, -430, -429, -428, -424, -423, -420, -418, -416, -415, -412, -411, -410, -409, -407, -395, -394, -393, -392, -389, -387, -384, -383, -382, -381, -379, -378, -376, -375, -373, -372, -366, -364, -363, -362, -361, -358, -356, -355, -349, -348, -346, -332, -331, -329, -328, -325, -324, -321, -320, -319, -318, -317, -316, -315, -314, -313, -310, -309, -308, -307, -306, -301, -300, -299, -298, -297, -295, -294, -292, -290, -288, -287, -286, -285, -284, -283, -282, -280, -278, -277, -274, -273, -272, -271, -270, -269, -268, -266, -265, -264, -261, -260, -259, -257, -256, -255, -254, -253, -252, -250, -249, -248, -247, -244, -243, -242, -241, -240, -239, -237, -236, -235, -234, -233, -232, -231, -230, -229, -225, -224, -223, -222, -219, -218, -215, -213, -212, -211, -207, -206, -204, -203, -202, -201, -200, -199, -198, -197, -196, -194, -193, -192, -190, -189, -186, -185, -182, -181, -180, -179, -177, -176, -175, -174, -173, -172, -171, -170, -169, -168, -167, -166, -165, -164, -155, -151, -150, -148, -146, -144, -143, -142, -141, -138, -137, -136, -132, -131, -130, -129, -128, -127, -126, -125, -123, -122, -121, -120, -119, -118, -117, -116, -115, -114, -113, -112, -111, -110, -107, -106, -105, -103, -102, -99, -98, -97, -96, -95, -94, -91, -89, -88, -86, -84, -83, -82, -79, -78, -72, -70, -68, -66, -65, -64, -60, -59, -57, -56, -55, -54, -53, -52, -51, -49, -47, -46, -45, -44, -43, -42, -40, -39, -38, -37, -36, -33, -31, -30, -29, -28, -27, -24, -21, -20, -18, -15, -14, -13, -9, -7, -6, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 70, 71, 72, 74, 75, 76, 79, 83, 84, 92, 95, 99];

const bn_en = [-182, -166, -143, -131, -45, -39, -33, -30, -13];
const hi_en = [-206, -204, -202, -194, -193, -192, -186, -185, -176, -175, -169, -142, -140, -134, -127, -126, -116, -115, -113, -112, -103, -102, -99, -96, -95, -94, -91, -89, -86, -84, -82, -66, -65, -64, -58, -56, -55, -53, -52, -49, -37, -36, -29, -28, -27, -26, -24, -20, -18, -14, -9, -7, -6, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 70, 71, 72, 74, 75, 76, 79, 80, 81, 82, 83, 84, 85, 86, 87, 92, 93, 94, 95, 96, 97, 98, 99, 100];
const ta_en = [-168, -98, -88, -79, -70, -42, -31];
const te_en = [-105, -78, -60, -59, -51, -43, -38, -15];
const gu_en = [-114, -107];
const kn_en = [-179, -170, -137, -110, -106, -97, -83, -72, -68, -57, -46];
const ml_en = [-201, -165, -164, -141];
const mr_en = [-203, -177, -150, -146];
const od_en = [-181, -155, -136];
const pu_en = [-138, -132, -130, -128];
const as_en = [-180, -174, -173, -172, -148, -144];

export function isPureHindi(book: number) {
  return hi.includes(book);
}

export function isBengali(book: number) {
  return bn.includes(book);
}
export function isHindi(book: number) {
  return hi.includes(book);
}
export function isTamil(book: number) {
  return ta.includes(book);
}
export function isTelugu(book: number) {
  return te.includes(book);
}
export function isGujrati(book: number) {
  return gu.includes(book);
}
export function isMalayalam(book: number) {
  return ml.includes(book);
}
export function isKannada(book: number) {
  return kn.includes(book);
}
export function isMarathi(book: number) {
  return mr.includes(book);
}
export function isOdia(book: number) {
  return od.includes(book);
}
export function isPunjabi(book: number) {
  return pu.includes(book);
}
export function isAssamese(book: number) {
  return as.includes(book);
}

export function isVernacular(sid: any) {
  return (isBengali(sid) || isHindi(sid) || isTamil(sid) || isTelugu(sid) || isGujrati(sid) || isMalayalam(sid) || isKannada(sid) || isMarathi(sid) || isOdia(sid) || isPunjabi(sid) || isAssamese(sid));
}

export function getVernacularLanguage(sid: any) {
  let language = "";

  if (isHindi(sid)) language = "hindi";
  else if (isBengali(sid)) language = "bengali";
  else if (isMalayalam(sid)) language = "malayalam";
  else if (isTamil(sid)) language = "tamil";
  else if (isTelugu(sid)) language = "telugu";
  else if (isGujrati(sid)) language = "gujrati";
  else if (isKannada(sid)) language = "kannada";
  else if (isMarathi(sid)) language = "marathi";
  else if (isOdia(sid)) language = "odia";
  else if (isPunjabi(sid)) language = "punjabi";
  else if (isAssamese(sid)) language = "assamese";

  return language;
}

export function getPackageQuesLanguage(studentId: number) {
  let packageLang = "";

  if (en.includes(studentId)) packageLang = "English";
  else if (hi.includes(studentId)) packageLang = "Hindi";
  else if (bn.includes(studentId)) packageLang = "Bengali";
  else if (ml.includes(studentId)) packageLang = "Malayalam";
  else if (ta.includes(studentId)) packageLang = "Tamil";
  else if (te.includes(studentId)) packageLang = "Telugu";
  else if (gu.includes(studentId)) packageLang = "Gujrati";
  else if (kn.includes(studentId)) packageLang = "Kannada";
  else if (mr.includes(studentId)) packageLang = "Marathi";
  else if (od.includes(studentId)) packageLang = "Odia";
  else if (pu.includes(studentId)) packageLang = "Punjabi";
  else if (as.includes(studentId)) packageLang = "Assamese";

  return packageLang;
}

export function getPackageQuesLocale(studentId: number) {

  if (en.includes(studentId)) return "en";
  if (hi.includes(studentId)) return "hi";
  if (bn.includes(studentId)) return "bn";
  if (ml.includes(studentId)) return "ml";
  if (ta.includes(studentId)) return "ta";
  if (te.includes(studentId)) return "te";
  if (gu.includes(studentId)) return "gu";
  if (kn.includes(studentId)) return "kn";
  if (mr.includes(studentId)) return "mr";
  if (od.includes(studentId)) return "od";
  if (pu.includes(studentId)) return "pu";
  if (as.includes(studentId)) return "as";
}

export function getVideoLanguage(studentId: number) {
  let videoLang = "";

  if (en.includes(studentId)) videoLang = "English";
  else if (hi.includes(studentId)) videoLang = "Hindi";
  else if (bn.includes(studentId)) videoLang = "Bengali";
  else if (ta.includes(studentId)) videoLang = "Tamil";
  else if (te.includes(studentId)) videoLang = "Telugu";
  else if (gu.includes(studentId)) videoLang = "Gujrati";
  else if (ml.includes(studentId)) videoLang = "Malayalam";
  else if (kn.includes(studentId)) videoLang = "Kannada";
  else if (mr.includes(studentId)) videoLang = "Marathi";
  else if (od.includes(studentId)) videoLang = "Odia";
  else if (pu.includes(studentId)) videoLang = "Punjabi";
  else if (as.includes(studentId)) videoLang = "Assamese";

  else if (bn_en.includes(studentId)) videoLang = "Bengali";
  else if (hi_en.includes(studentId)) videoLang = "Hindi";
  else if (ta_en.includes(studentId)) videoLang = "Tamil";
  else if (te_en.includes(studentId)) videoLang = "Telugu";
  else if (gu_en.includes(studentId)) videoLang = "Gujrati";
  else if (kn_en.includes(studentId)) videoLang = "Kannada";
  else if (ml_en.includes(studentId)) videoLang = "Malayalam";
  else if (mr_en.includes(studentId)) videoLang = "Marathi";
  else if (od_en.includes(studentId)) videoLang = "Odia";
  else if (pu_en.includes(studentId)) videoLang = "Punjabi";
  else if (as_en.includes(studentId)) videoLang = "Assamese";

  return videoLang;
}

export const getLanguageFromLocale = (locale: string) => {
  switch (locale) {
    case "en": return "English";
    case "hi": return "Hindi";
    default: return "English";
  }
}

export const getLocaleFromLanguage = (language: string) => {
  switch (language) {
    case "english": return "en";
    case "hindi": return "hi";
    default: return "en";
  }
}

export const getLanguageFromNonNativeLocale = (locale: string, hindiIncluded: boolean = false) => {
  switch (locale) {
    case "bn-en": return "Bengali";
    case "hi-en": return hindiIncluded ? "Hindi" : "";
    case "ta-en": return "Tamil";
    case "te-en": return "Telugu";
    case "gu-en": return "Gujrati";
    case "kn-en": return "Kannada";
    case "ml-en": return "Malayalam";
    case "mr-en": return "Marathi";
    case "od-en": return "Odia";
    case "pu-en": return "Punjabi";
    case "as-en": return "Assamese";
    default: return "";
  }
}

export function isPageLanguageValid(language: string) {
  const validLanguages: string[] = ["marathi", "odia", "assamese", "hindi", "kannada", "malayalam", "bengali", "punjabi", "tamil", "telugu", "gujarati", "gujrati"];

  return validLanguages.indexOf(language) != -1;
}

export function getExcludedStudentIds(): number[] {
  return [];
  // return [-41];
}